<div class="loading-spinner" *ngIf="isLoading"></div>
<form *ngIf="!isLoading" [formGroup]="formGroup" (ngSubmit)="submit()" class="form-box">
    <div class="form-title">
        <h1>Register</h1>
    </div>
    <div class="card--row">
        <br>
        
        <div *ngFor='let b of dbForm.webFormFieldList'>
            <mat-form-field appearance="outline">
                <mat-label>{{b.fieldLabel}}</mat-label>
                <input matInput formControlName={{b.field}} maxlength={{b.maxLength}} required={{b.required}}>
                <mat-error *ngIf=b.hasError>{{b.errorMessage}}</mat-error>
            </mat-form-field>
        </div>
    
        <ng-container *ngFor="let m of multiBtnList">
            <p>
              {{ m.fieldLabel }}
                <mat-button-toggle-group
                    required="{{m.required}}"
                    formControlName="{{m.field}}"
                >
                    <mat-button-toggle *ngFor="let option of m.defaultValue" [value]="option[0]">
                        {{ option[1] }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </p>
        </ng-container>

        <mat-checkbox style="color:#616161" color="primary" formControlName="optIn">
            <span [innerHTML]="dbForm.optinText"></span>
        </mat-checkbox>
    </div>
    <button mat-raised-button type="submit" class="button--submit" [disabled]="formGroup.invalid"><span style="margin:0;color:white">Register</span></button>
    <footer>
        <span>{{dbForm.footerTitle}}</span>
        <span><a href="mailto:{{dbForm.footerEmail}}">{{dbForm.footerEmail}}</a></span>
        <span [innerHTML]="dbForm.footerAddress"></span>
    </footer>
</form>
    

